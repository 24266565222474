


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import CabinetMainPage from '@/_modules/promo-cabinet/components/cabinet-main-page/cabinet-main-page.vue';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import PromoHead from '@/views/components/promoPage/head.vue';
import ConfirmUnsavedLeave from '@/views/components/popups/confirm-unsaved-leave.vue';

enum CabinetTab {
  MAIN = 'main',
  LOBBY = 'lobby',
  PROGRAM = 'program',
  MAP = 'map',
  EXPONENTS = 'exponents',
  VISITORS = 'visitors',
  STATISTICS = 'statistics',
}

@Component({
  name: 'cabinet',
  components: {
    CabinetMainPage,
    IconArrowLeft,
    PromoHead,
    ConfirmUnsavedLeave,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      contact: 'contact/promoPageStore',
      promoPage: 'promoPage/promoPageStore',
      isPromoPageLoading: 'isPromoPageLoading/promoPageStore',
    }),
  },
})
export default class Cabinet extends Vue {

  public readonly CabinetTab: typeof CabinetTab = CabinetTab;

  public readonly event: TEvent;
  public readonly contact: TContact;

  public get isNavigationVisible(): boolean {
    return !this.$route.meta.hideCabinetNavigation;
  }

  public get currentTab(): CabinetTab {
    switch (this.$route.name) {

      case 'promo-page-cabinet-lobby':
        return CabinetTab.LOBBY;

      case 'promo-page-cabinet-exponents':
        return CabinetTab.EXPONENTS;

      case 'promo-page-cabinet-program':
        return CabinetTab.PROGRAM;

      default:
        return CabinetTab.MAIN;

    }
  }

  public cabinetHeaderArrowClick(): void {
    this.$router.push({
      name: 'promo-page-edit',
      params: {
        eventId: this.$route.params.eventId
      }
    });
  }

}
